.HomePage {
  text-align: center;
  color: white;
  background-color: #3c3e3f;
}


#homepage-container{
  padding: 2em;
}

#headline-col{
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#badge-row{
  justify-content: center;
  padding-bottom: 5em;
}

img#playstore-logo {
  width: 50%;
  min-width: 120px;
}
img#appstore-logo {
  width: 39%;
  min-width: 105px;
}

#header-img{
  margin: 0;
  padding: 0;
  width: 100%;
  /* height: 60%;
  /* object-fit: cover; */
  /* object-position: 0% 0%; */
  box-shadow: 0 26px 20px -20px black;
}
