.swiper-img{
    width: 17em;
}

/* Only for large screens */
@media (min-width: 800px) {
    .swiper-img{
        width: 25em;
    }
  }

/* Removes the grey background */
.swiper-3d .swiper-slide-shadow {
    background: rgba(0,0,0,0);
}

.mySwiper{
    height: 100%; 
    /* max-height: 100vw; */
    min-height: 0;
    min-width: 0;
    max-width: 100vw; 
    width: 100%;
    overflow: hidden;
  }

.swiper-wrapper{
  max-height: 100%;
  height: 100%;
  display: flex;  
}

.swiper-slide {
    justify-content: center;
}
